

.Panel_Account_Settings_P .top-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.Panel_Account_Settings_P p {
  margin-bottom: 0px;
}

.Panel_Account_Settings_P .to-change-password {
  text-align: justify;
  padding-right: 9px;
  border-right: 3px solid red;
}

