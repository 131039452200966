
.NotFound_P {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 30px auto;
  padding: 9px 30px 45px 30px;
  border-radius: 3px;
  width: 540px;
  max-width: 80%;
  background-color: white;
}

.NotFound_P .Action {
  margin: auto;
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: fit-content;
}

.NotFound_P .current-path {
  text-align: left;
  direction: ltr;
  word-break: break-all;
  margin-bottom: 0px;
}