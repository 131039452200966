




.button-normal {
  list-style-type: none;
  border: 1px solid #1c39bb;
  border-radius: 3px;
  padding: 6px 12px;
  margin-top: 9px;
  text-decoration: none;
}

.button-normal a {
  text-decoration: none;
}

.button-normal:hover {
  border: 1px solid #1c39bb;
  background-color: #1c39bb;
  color: white;
}

.button-normal:active {
  border: 1px solid #deb645;
  background-color: #deb645;
}



.button-warning {
  height: fit-content;
  background-color: white;
  color: red;
  border: 1px solid red;
}

.button-warning:hover {
  background-color: red;
  color: white;
}

.button-warning:active {
  background-color: rgba(255, 0, 0, 0.45);
  color: white;
  border: 1px solid rgba(255, 255, 255, 0);
}




.error-message {
  margin-top: -9px;
  margin-bottom: 18px;
  color: red;
  max-width: 300px;
  word-break: break-all;
  user-select: text !important;
}

.error-message span {
  font-weight: bold;
}


.Support_Div {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; 
  background-color: white;
  position: fixed;
  width: fit-content;
  padding: 0px 18px;
  bottom: 45px;
  left: 0px;
  text-align: center;
  font-size: small;
}

.Support_Div a {
  color: gray;
}

.hidden {
  display: none;
}


/* Default Font */
@font-face {
  font-family: VazirmatnRegular;
  src: url(./fonts/Vazirmatn-Regular.woff2);
}



* {
  font-family: VazirmatnRegular ;
  /* user-select: none; */
}


body {
  background-image: url(./body_wallpaper.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}



a {
  color: #1c39bb;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}



h1 {
  color: #1c39bb;
}



input {
  border: 1px solid black;
  border-bottom: 2px solid black;
  border-radius: 3px;
  padding: 6px 12px
}



button {
  border: 0px solid #1c39bb;
  border-radius: 3px;
  padding: 6px 18px;
  background-color: #1c39bb;
  color: white;
  font-size: large;
  cursor: pointer;
}

button:hover {
  background-color: #1c39bbac;
}

button:active {
  background-color: #deb645;
}
