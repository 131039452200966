.Panel_Sidebar_C {
  width: fit-content;
  min-width: 240px;
  max-width: 30%;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 3px 18px;
  border-radius: 3px;
  position: -webkit-sticky;
  position: sticky;
  top: 30px;

}

.Panel_Sidebar_C .Title {
  margin: 0px;
  padding-top: 24px;
  font-size: large;
}



.Panel_Sidebar_C .Top {
  /* border: 1px solid red; */
  margin-top: 18px;
}

.Panel_Sidebar_C .Top select {
  width: 100%;
  border-radius: 3px;
  padding: 6px;
  font-size: medium;
}

.Panel_Sidebar_C .Top option {
  padding: 6px;
  font-size: medium;
}


.Panel_Sidebar_C .Top .Group_Actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.Panel_Sidebar_C .Top .Group_Actions a {
  padding: 3px 0pc;
  text-decoration: none;
  width: 48%;
  text-align: center;

}







.Panel_Sidebar_C .List {
  margin: 18px 0px;
}

.Panel_Sidebar_C .List a{
  text-decoration: none;
}