
.Auth_Header_C {
  padding: 3px 18px 3px 18px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  user-select: none;
  display: flex;
  justify-content: space-between;
}

.Auth_Header_C .Right {
  display: flex;
  align-items: center;
  align-content: center;
}

.Auth_Header_C .Right p {
  padding-inline: 9px;
  margin: 0px 0px 0px 0px;
}

.Auth_Header_C .Right img {
  height: 60px;
}


.Auth_Header_C .Left {
  display: flex;
  align-items: center;
  align-content: center;
}