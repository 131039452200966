
.Panel_Header_C {
  padding: 3px 18px 3px 18px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: relative;
}

.Panel_Header_C .Right {
  display: flex;
  align-items: center;
  align-content: center;
}

.Panel_Header_C .Right p {
  padding-inline: 9px;
  margin: 0px 0px 0px 0px;
}

.Panel_Header_C .Right img {
  height: 60px;
}


.Panel_Header_C .Left {
  display: flex;
  align-items: center;
  align-content: center;
}

.Panel_Header_C .Left .Account_Settings {
  border: 1px solid #1c39bb;
  padding: 6px 18px;
  text-decoration: none;
  border-radius: 3px;
  margin-left: 9px;
}


.Panel_Header_C .Left .Account_Settings:hover {
  border: 1px solid #1c39bb;
  background-color: #1c39bb;
  color: white;
}

.Panel_Header_C .Left .Account_Settings:active {
  border: 1px solid #deb645;
  background-color: #deb645;
}