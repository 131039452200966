

.Auth .Content 
{
  background-color: white;
  margin: 30px ;
  width: fit-content;
  min-width: 300px;
  max-width: 80%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1px 30px 30px 30px;
  border-radius: 3px;
  text-align: center;
}


.Auth .Content h1 {
  padding-bottom: 0px;
  margin-bottom: 30px;
}



.Auth .Content .Form input {
  text-align: right;
  width: 90%;
  margin-top: 3px;
}


.Auth .Content .Form p {
  margin-bottom: 0px;
  margin-right: 6px;
  /* margin-top: 6px; */
  /* font-weight: bold; */
  text-align: right;
}

.Auth .Content .Form ul {
  text-align: right;
  margin-bottom: -18px;
}


.Auth .Content .Form button {
  margin-top: 6px;
}


.Auth .Content .Others {
  padding-top: 9px;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.Auth .Content .Others p {
  margin-bottom: 0px;
}

