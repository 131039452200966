




.Group_Search_P .Container {
  display: flex;
}
/* 
.Group_Search_P .Container * {
  border: 1px solid red;
} */

.Group_Search_P .Container .Sidebar {
  flex-grow: 1;

}

.Group_Search_P .Container .Content {
  margin: 30px;
  width: 100%;
  min-height: 600px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 3px;
  padding: 6px 36px;
  padding-bottom: 36px;
}


.Group_Search_P .Top-Holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Group_Search_P .Top-Holder .Action-Buttons {
  height: fit-content;
}

.Group_Search_P .Top-Holder .Action-Buttons * {
  margin-right: 3px;
}


.Group_Search_P .back-to-home {
  float: left;
}







































.Group_Search_P .Content .Loading {
  text-align: center;
  align-items: center;
  align-content: center;
  height: 100%;
}

.Group_Search_P .Content .Loading p {
  margin: 0px;
  margin-bottom: -18px;
  margin-top: -18px;
}

/* Loading */

.Group_Search_P .Content .Loading .lds-ellipsis,
.Group_Search_P .Content .Loading .lds-ellipsis div {
  box-sizing: border-box;
}
.Group_Search_P .Content .Loading .lds-ellipsis {
  color: #1c39bb;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Group_Search_P .Content .Loading .lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.Group_Search_P .Content .Loading .lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: panel-p-content-lds-ellipsis1 0.6s infinite;
}
.Group_Search_P .Content .Loading .lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: panel-p-content-lds-ellipsis2 0.6s infinite;
}
.Group_Search_P .Content .Loading .lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: panel-p-content-lds-ellipsis2 0.6s infinite;
}
.Group_Search_P .Content .Loading .lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: panel-p-content-lds-ellipsis3 0.6s infinite;
}
@keyframes panel-p-content-lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes panel-p-content-lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes panel-p-content-lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

